<template>
  <div
    ref="playerBox"
    class="playerBox"
    :style="{
      width: boxWidth,
      height: boxHeight,
    }"
  >
    <!-- 片头广告 -->
    <div id="OP" v-if="isShowOP">
      <!-- 音量 -->
      <!-- <div class="volume" @click="openVolume('OP')">
        <img :src="httpImgM + '/player-volume-open.png'" class="volume-icon" v-if="isOPVolume" />
        <img :src="httpImgM + '/player-volume-close.png'" class="volume-icon" v-else />
      </div> -->
    </div>
    <!-- 主播放器 -->
    <!-- <div class="player-container">
      <div id="TSPPlayer" ref="TSPPlayer"></div>
    </div> -->
    <div class="player-container">
      <div
        :style="`position:absolute;left: 0; top: 0;height: 100%;width: 100%`"
        id="TSPPlayer"
        ref="TSPPlayer"
      >
        <img
          v-if="logoUrl && !leftLogo"
          :style="`position: absolute; right: 20px;bottom: 20px; width: auto;height:30px`"
          :src="logoUrl"
        />
        <img
          v-if="openType == 2"
          :style="`position: absolute; left: 20px;bottom: 20px; width: auto;height:30px`"
          src="https://staticfile.xlcwx.com/m-images/yth/lzimg/jx/xlcbg.png"
        />
      </div>
    </div>
    <!-- 片尾广告 -->
    <div id="ED" v-if="isShowED">
      <!-- 音量 -->
      <!-- <div class="volume" @click="openVolume('ED')">
        <img :src="httpImgM + '/player-volume-open.png'" class="volume-icon" v-if="isEDVolume" />
        <img :src="httpImgM + '/player-volume-close.png'" class="volume-icon" v-else />
      </div> -->
    </div>

    <!-- tsp配置提示 -->
    <div class="errorTips" v-if="config.status != 200">
      {{ config.message }}
    </div>
    <!-- 视频加载视频提示 -->
    <div class="errorTips" v-if="checkInfo.status != 200">
      {{ checkInfo.message }}
    </div>
  </div>
</template>

<script>
import Player from "xgplayer";
import HlsJsPlayer from "xgplayer-hls.js";
const _keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },

    openType: {
      type: String,
      default: "1",
    },
    aDType: {
      type: String,
      default: "none",
    },
    aDSwiper: {
      type: Array,
      default: () => [
        {
          url: "https://img2.baidu.com/it/u=1688153644,1490540018&fm=253&fmt=auto&app=120&f=JPEG?w=650&h=495",
        },
        {
          url: "https://img1.baidu.com/it/u=1323422903,4049363711&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=889",
        },
        {
          url: "https://img1.baidu.com/it/u=2676533820,1803958359&fm=253&fmt=auto&app=138&f=JPEG?w=600&h=400",
        },
      ],
    },
    aDsrc: {
      type: String,
      default: "https://www.baidu.com",
    },
    aDWidth: {
      type: [String],
      default: "50%",
    },
    aDHeight: {
      type: [String],
      default: "50%",
    },
    leftLogo: {
      type: Boolean,
      defalut: false,
    },
    logoUrl: {
      type: String,
      default: "",
    },
    logoWidth: {
      type: [String, Number],
      default: 105,
    },
    logoHeight: {
      type: [String, Number],
      default: 50,
    },
    aDurl: {
      type: String,
      default:
        "//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4",
    },
    url: {
      type: String,
      default: "",
    },
    width: {
      type: [String],
      default: "600px",
    },
    height: {
      type: [String],
      default: "337px",
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    poster: {
      type: String,
      default: "",
    },
    // 清晰度
    definition: {
      type: Array,
      default: () => [],
    },
    // 进度条标识
    progressDot: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "MP4",
    },
    volume: {
      type: [String, Number],
      default: 0.6,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    autoplayMuted: {
      type: Boolean,
      default: false,
    },
    pip: {
      type: Boolean,
      default: false,
    },
    screenShot: {
      type: Boolean,
      default: false,
    },
    // 前后广告
    videoAdOverlay: {
      type: Boolean,
      default: true,
    },
    // 跳过时间
    skipTime: {
      type: Number,
      default: 3,
    },
    // 广告链接
    playeraDurlOP: {
      type: String,
      default: "",
    },
    playeraDurlOPTarget: {
      type: String,
      default: "",
    },
    playeraDurlEP: {
      type: String,
      default: "",
    },
    accessKeyId: {
      type: String,
      default: "",
    },
    accessKeySecret: {
      type: String,
      default: "",
    },
    playeraDurlEPTarget: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      httpImgM: "https://images.xlcwx.com/xlcwx/m",
      httpImgPC: "https://images.xlcwx.com/xlcwx/pc",

      checkInfo: {
        status: 200,
        message: "",
      }, // 是否可以加载视频接口

      config: {
        status: 200,
        message: "",
      }, // 接口获取的配置

      boxWidth: 0, // 容器宽度
      boxHeight: 0, // 容器高度

      options: {}, // 参数

      isShowOP: false, // 是否显示片头播放器
      isOPVolume: false, // 片头是否开启声音

      isShowED: false, // 是否显示片尾播放器
      isEDVolume: false, // 片尾是否开启声音

      OPPlayer: null, // 片头播放器
      player: null, // 主播放器
      EDPlayer: null, // 片尾播放器

      isAutoplay: false, // 正片是否自动播放
      isVideoEnd: false, // 正片是否播放结束

      adUrl: {
        url: "",
      }, // 当前广告

      watermark: {
        url: "",
        top: "0",
        left: "0",
        maxLeft: 0,
        maxTop: 0,
        timer: null,
        width: 0,
        height: 0,
        whereNum: 1, // 1左上 2右下
      }, // 水印
    };
  },
  methods: {
    // 获取播放器配置
    getConfig() {
      return new Promise((resolve, reject) => {
        this.ajax({
          url: "https://gateway.juxue.cloud/resmanage/sdk/getVideoSet",
          header: {
            accesskey: this.options.accessKeyId,
            accesssecret: this.options.accessKeySecret,
          },
        })
          .then((res) => {
            resolve(JSON.parse(res));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取广告
    getAD() {
      return new Promise((resolve, reject) => {
        this.ajax({
          url: "https://gateway.juxue.cloud/resmanage/sdk/getVideoADSetting",
          header: {
            accesskey: this.options.accessKeyId,
            accesssecret: this.options.accessKeySecret,
          },
        })
          .then((res) => {
            res = JSON.parse(res);
            res.data.url = "";
            this.adUrl = res.data;
            resolve(this.adUrl)
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 销毁
    destroy() {
      try {
        this.player.destroy();
      } catch (error) {
        console.log("playerDestroy", error);
      }
      try {
        this.OPPlayer && this.OPPlayer.destroy();
      } catch (error) {
        console.log("OPPlayer", error);
      }
      try {
        this.EDPlayer && this.EDPlayer.destroy();
      } catch (error) {
        console.log("EDPlayer", error);
      }
      clearTimeout(this.watermark.timer);
    },

    // OP加载
    initOP() {
      console.log("加载片头");
      this.isShowOP = true;
      this.$nextTick(() => {
        const Temp = this.usePlug(this.options.OPAD.url);
        this.OPPlayer = new Temp({
          id: "OP", // dom元素id
          lang: "zh-cn", // 文本语言
          url: this.options.OPAD.url,
          width: this.setWidth(this.options), // 宽
          height: this.setHeight(this.options), // 高
          volume: 1,
          autoplay: this.config.data.autoplayStatus == 1,
          playsinline: true, // 内联模式 ios播放时不会进入全屏
          disableProgress: this.config.data.progressDragStatus == 0, // 禁止拖拽进度条
          download: this.config.data.downloadStatus == 1, // 视频下载
          videoInit: true, // 显示首帧
          playbackRate: this.setPlaybackRate(), // 倍速
          lastPlayTime: 0, // 视频起播时间（单位：秒）
          lastPlayTimeHideDelay: 0, // 提示文字展示时长（单位：秒）
        });
        // 片头暂停
        this.OPPlayer.on("pause", () => {
          setTimeout(() => {
            this.isShowOP && this.initPauseAD("op");
          }, 100);
        });
        // 片头播放结束加载正片
        this.OPPlayer.on("ended", () => {
          this.OPPlayer.destroy();
          this.options.OPAD = null;
          this.isShowOP = false;
          this.mainPlay();
        });
        // 音量
        this.OPPlayer.on("volumechange", (e) => {
          this.isOPVolume = e.volume == 1;
        });
      });
    },
    OPEnd() {
      this.isShowOP = false;
      this.mainPlay();
    },

    // 正片播放
    mainPlay() {
      if (this.player) {
        this.player.start(this.options.url);
        this.$nextTick(() => {
          if (this.isAutoplay) {
            this.player.play();
          }
        });
      }
    },

    // ED加载
    initED() {
      if (this.config.data.endStatus == 1) {
        console.log("加载片尾");
        this.isShowED = true;
        this.$nextTick(() => {
          const Temp = this.usePlug(this.config.data.endVideoUrl);
          this.EDPlayer = new Temp({
            id: "ED", // dom元素id
            lang: "zh-cn", // 文本语言
            url: this.config.data.endVideoUrl,
            width: this.setWidth(this.options), // 宽
            height: this.setHeight(this.options), // 高
            volume: 1,
            autoplay: this.config.data.autoplayStatus == 1,
            playsinline: true, // 内联模式 ios播放时不会进入全屏
            disableProgress: this.config.data.progressDragStatus == 0, // 禁止拖拽进度条
            download: this.config.data.downloadStatus == 1, // 视频下载
            videoInit: true, // 显示首帧
            playbackRate: this.setPlaybackRate(), // 倍速
            lastPlayTime: 0, // 视频起播时间（单位：秒）
            lastPlayTimeHideDelay: 0, // 提示文字展示时长（单位：秒）
          });
          // 片尾暂停
          this.EDPlayer.on("pause", () => {
            setTimeout(() => {
              this.isShowED && this.initPauseAD("ed");
            }, 100);
          });
          // 片头播放结束加载正片
          this.EDPlayer.on("ended", () => {
            this.EDPlayer.destroy();
            this.isShowED = false;
          });
          // 音量
          this.EDPlayer.on("volumechange", (e) => {
            this.isEDVolume = e.volume == 1;
          });
        });
      }
    },
    EDEnd() {
      this.isShowED = false;
    },

    // 声音开启
    openVolume(type) {
      if (type == "OP") {
        this.isOPVolume
          ? (this.OPPlayer.volume = 0)
          : (this.OPPlayer.volume = 1);
      } else if (type == "ED") {
        this.isEDVolume
          ? (this.EDPlayer.volume = 0)
          : (this.EDPlayer.volume = 1);
      }
    },

    // 获取是否可以加载视频
    getCheckBrowser(options) {
      return new Promise((resolve, reject) => {
        const baseUrl = options.requestUrl
          ? options.requestUrl
          : "https://gateway.juxue.cloud";
        this.ajax({
          url: baseUrl + "/resmanage/sdk/checkBrowser",
        })
          .then((res) => {
            resolve(JSON.parse(res));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 视频播放链接替换
    setPlayUrl(options) {
      const urlArr = options.url.split(".");
      const suffix = urlArr[urlArr.length - 1];
      if (suffix.toLowerCase() == "m3u8") {
        return new Promise((resolve, reject) => {
          this.ajax({
            url: options.url,
          })
            .then((res) => {
              let result = res;
              let url = "";
              const regex = /.m3u8.key/g;
              let newStr = result.replace(
                regex,
                ".m3u8.key&verifyKeyUrlUse=wwb"
              );

              const u = navigator.userAgent;
              const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
              if (isiOS) {
                const auth = new Buffer(
                  JSON.stringify({
                    accessKeyId: options.accessKeyId,
                    accessKeySecret: options.accessKeySecret,
                  })
                ).toString("base64");
                newStr = newStr + `&agent=IOS_WAP&auth=${auth}`;
              }

              const blob = new Blob([newStr], {
                type: "application/x-mpegURL",
              });
              url = URL.createObjectURL(blob);
              resolve(url);
            })
            .catch((err) => {
              reject(err);
            });
        });
      } else {
        return options.url;
      }
    },
    // xhr请求
    ajax(options) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(options.method ? options.method : "GET", options.url);
        if (options.header) {
          for (const key in options.header) {
            xhr.setRequestHeader(key, options.header[key]);
          }
        }
        xhr.addEventListener("loadend", () => {
          if (xhr.status == 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(xhr.response));
          }
        });
        xhr.send();
      });
    },
    // 判断视频格式使用插件
    usePlug(url) {
      const urlArr = url.split(".");
      const suffix = urlArr[urlArr.length - 1];
      if (suffix.toLowerCase() != "mp4") {
        return HlsJsPlayer;
      }
      return Player;
    },

    // 宽度
    setWidth(options) {
      this.boxWidth = options.width ? options.width : "100%";
      return this.boxWidth;
    },
    // 高度
    setHeight(options) {
      this.boxHeight = options.height ? options.height : "400px";
      return this.boxHeight;
    },
    // 倍速
    setPlaybackRate() {
      if (this.config.data.multiplePlayStatus == 1) {
        return [0.5, 0.75, 1.0, 1.25, 1.5, 2];
      } else {
        return [];
      }
    },
    // logo
    setLogo() {
      const logoInfo = {
        url: this.httpImgPC + "/logo.png",
        size: 1,
        style: {},
      };
      if (this.config.data.logoStatus == 1) {
        logoInfo.url = this.config.data.logoUrl;
        logoInfo.size = this.config.data.logoSize
          ? this.config.data.logoSize
          : 1;
        this.config.data.logoPositionX = this.config.data.logoPositionX
          ? this.config.data.logoPositionX
          : 0;
        this.config.data.logoPositionY = this.config.data.logoPositionY
          ? this.config.data.logoPositionY
          : 0;
        this.config.data.logoPosition = this.config.data.logoPosition
          ? this.config.data.logoPosition
          : 2;
      } else {
        this.config.data.logoPositionX = 20;
        this.config.data.logoPositionY = 20;
        this.config.data.logoPosition = 2;
      }
      // 左上
      if (this.config.data.logoPosition == 1) {
        logoInfo.style.left = this.config.data.logoPositionX + "px";
        logoInfo.style.top = this.config.data.logoPositionY + "px";
      }
      // 右上
      if (this.config.data.logoPosition == 2) {
        logoInfo.style.right = this.config.data.logoPositionX + "px";
        logoInfo.style.top = this.config.data.logoPositionY + "px";
      }
      // 左下
      if (this.config.data.logoPosition == 3) {
        logoInfo.style.left = this.config.data.logoPositionX + "px";
        logoInfo.style.bottom = this.config.data.logoPositionY + "px";
      }
      // 右下
      if (this.config.data.logoPosition == 4) {
        logoInfo.style.right = this.config.data.logoPositionX + "px";
        logoInfo.style.bottom = this.config.data.logoPositionY + "px";
      }
      const img = new Image();
      img.src = logoInfo.url;
      img.onload = () => {
        if (this.config.data.logoStatus == 1) {
          logoInfo.style.width = img.width * logoInfo.size + "px";
          logoInfo.style.height = img.height * logoInfo.size + "px";
        } else {
          logoInfo.style.width = "126px";
          logoInfo.style.height = "28px";
        }
        this.$nextTick(() => {
          const playerEl = document.getElementById("TSPPlayer");
          const logos = playerEl.querySelectorAll(".logo");
          if (logos) {
            // 删除logo
            logos.forEach((i) => playerEl.removeChild(i));
          }
          const img = document.createElement("img");
          img.setAttribute("src", logoInfo.url);
          img.setAttribute("class", "logo");
          let style = "";
          for (const key in logoInfo.style) {
            style = style + `${key}:${logoInfo.style[key]};`;
          }
          img.setAttribute("style", style);
          playerEl.appendChild(img);
        });
      };
    },
    // 水印
    setWatermark(options) {
      if (this.config.data.watermarkStatus != 1) return;
      clearTimeout(this.watermark.timer);
      this.watermark.url = this.config.data.watermarkUrl;
      this.watermark.top = "0";
      this.watermark.left = "0";
      this.watermark.whereNum = 1;
      this.$nextTick(() => {
        const playerEl = document.getElementById("TSPPlayer");
        const watermark = playerEl.querySelector(".watermark");
        if (watermark) {
          playerEl.removeChild(watermark);
        }
        const img = new Image();
        img.src = this.watermark.url;
        img.onload = () => {
          const imgEl = document.createElement("img");
          imgEl.setAttribute("src", this.watermark.url);
          imgEl.setAttribute("class", "watermark");
          imgEl.setAttribute(
            "style",
            `transform: translate(${this.watermark.left}px, ${this.watermark.top}px)`
          );
          playerEl.appendChild(imgEl);
          imgEl.addEventListener("load", this.watermarkLoad(img));
        };
      });
    },
    // 水印加载
    async watermarkLoad(value) {
      clearTimeout(this.watermark.timer);
      const width = this.watermark.width
        ? parseInt(this.watermark.width)
        : value.width;
      const height = this.watermark.height
        ? parseInt(this.watermark.height)
        : value.height;
      this.watermark.width = width;
      this.watermark.height = height;
      // 最大left
      const calcLeft = this.$refs.TSPPlayer.offsetWidth - width;
      if (calcLeft > 0) {
        this.watermark.maxLeft = calcLeft;
      } else {
        this.watermark.maxLeft = 0;
      }
      // 最大top
      const calcTop = this.$refs.TSPPlayer.offsetHeight - height;
      if (calcTop > 0) {
        this.watermark.maxTop = calcTop;
      } else {
        this.watermark.maxTop = 0;
      }
      this.setWatermarkPosition();
    },
    // 水印位置随机
    setWatermarkPosition() {
      clearTimeout(this.watermark.timer);
      const playerEl = document.getElementById("TSPPlayer");
      const watermark = playerEl.querySelector(".watermark");
      if (this.watermark.whereNum == 1) {
        this.watermark.whereNum = 2;
        watermark.setAttribute(
          "style",
          `transform: translate(${this.watermark.maxLeft}px, ${this.watermark.maxTop}px)`
        );
      } else {
        this.watermark.whereNum = 1;
        watermark.setAttribute(
          "style",
          `transform: translate(${this.watermark.left}px, ${this.watermark.top}px)`
        );
      }
      this.watermark.timer = setTimeout(() => {
        this.setWatermarkPosition();
      }, 100000);
    },
    // 暂停时加载广告
    async initPauseAD(type = "main") {
      await this.getAD();
      console.log(this.adUrl)
      if (this.adUrl && this.adUrl.adUrl) {
        this.adUrl.url = this.adUrl.adUrl;

        let playerEl = null;
        if (type == "op") {
          playerEl = document.getElementById("OP");
        } else if (type == "main") {
          playerEl = document.getElementById("TSPPlayer");
        } else if (type == "ed") {
          playerEl = document.getElementById("ED");
        }

        // 广告盒子
        const adBox = playerEl.querySelector(".advertising-box");
        if (adBox) {
          playerEl.removeChild(adBox);
        }
        const box = document.createElement("div");
        box.setAttribute("class", "advertising-box");
        const container = document.createElement("div");
        container.setAttribute("class", "advertising-container");
        box.appendChild(container);

        // 广告
        const img = document.createElement("img");
        img.setAttribute("src", this.adUrl.adUrl);
        img.setAttribute("class", "advertising");
        container.appendChild(img);
        img.addEventListener("click", () => {
          window.open(this.adUrl.jumpUrl);
          this.$emit("adClick", {
            jumpUrl: this.adUrl.jumpUrl,
          });
        });

        // 关闭
        const closeDiv = document.createElement("div");
        closeDiv.setAttribute("class", "advertising-close");
        const closeSpan = document.createElement("span");
        closeSpan.innerHTML = "广告";
        closeDiv.appendChild(closeSpan);
        const closeImg = document.createElement("img");
        closeImg.setAttribute("src", this.httpImgM + "/close-line-1.png");
        closeImg.setAttribute("class", "advertising-close-img");
        closeDiv.appendChild(closeImg);
        closeDiv.addEventListener("click", () => {
          playerEl.removeChild(box);
        });

        container.appendChild(closeDiv);

        playerEl.appendChild(box);
      }
    },
    // 广告点击
    adClick() {
      window.open(this.adUrl.jumpUrl);
      this.$emit("adClick", {
        jumpUrl: this.adUrl.jumpUrl,
      });
    },
    // 关闭广告
    adClose() {
      this.adUrl.url = "";
    },

    // 随机获取指定范围内的值
    rand(m, n) {
      return Math.ceil(Math.random() * (n - m + 1) + m - 1);
    },
    // 清空数据
    clearData() {
      this.checkInfo = {
        status: 200,
        message: "",
      };
      this.config = {
        status: 200,
        message: "",
      };
      this.boxWidth = 0;
      this.boxHeight = 0;
      this.options = {};
      this.isShowOP = false;
      this.isOPVolume = false;
      this.isShowED = false;
      this.isEDVolume = false;
      this.OPPlayer = null;
      this.player = null;
      this.EDPlayer = null;
      this.isAutoplay = false;
      this.adUrl = {};
      this.watermark = {
        url: "",
        top: "0",
        left: "0",
        maxLeft: 0,
        maxTop: 0,
        timer: null,
        width: 0,
        height: 0,
        whereNum: 1,
      };
    },
  },
  async mounted() {
    this.options.url = this.url;
    this.options.accessKeyId = this.accessKeyId;
    this.options.accessKeySecret = this.accessKeySecret;
    this.options.width = "100%";
    this.options.height = "100%";

    try {
      this.config = await this.getConfig();
    } catch (error) {
      this.config = error;
    }
    if (this.config.status != 200) {
      return;
    }
    console.log(this.config);

    this.setWidth(this.options);
    this.setHeight(this.options);

    try {
      const isCheck = await this.getCheckBrowser(this.options);
      this.checkInfo = isCheck;
    } catch (error) {
      this.checkInfo = error;
    }
    if (this.checkInfo.status != 200) {
      return;
    }

    // 片头
    if (this.config.data.startStatus == 1) {
      this.options.OPAD = {
        url: this.config.data.startVideoUrl,
      };
      this.initOP();
    }

    // 正片
    if (!this.options.url) {
      console.log("未获取到视频链接");
      return;
    }

    // 是否自动播放
    this.isAutoplay = this.config.data.autoplayStatus == 1 ? true : false;
    this.options.autoplay = this.isAutoplay;
    let volume = 1;
    if (this.isAutoplay) {
      volume = 0;
    }
    if (this.isShowOP) {
      this.options.autoplay = false;
    }
    let that = this;
    const Temp = this.usePlug(this.options.url);
    this.player = new Temp({
      id: "TSPPlayer", // dom元素id
      url: that.options.url, // 播放链接
      lang: "zh-cn", // 文本语言
      playsinline: true, // 内联模式 ios播放时不会进入全屏
      autoplay: that.options.autoplay, // 自动播放
      preloADTime: 30, // 预加载时长(秒)
      minCachedTime: 5, // 当前播放时间距离已缓存资源片段结束点剩多长时间时开始请求新片段(秒)
      cors: true, // 请求是否跨域
      width: that.setWidth(that.options), // 宽
      height: that.setHeight(that.options), // 高
      volume, // 音量
      playbackRate: that.setPlaybackRate(), // 倍速
      lastPlayTime: that.options.lastPlayTime
        ? Number(that.options.lastPlayTime)
        : 0, // 视频起播时间（单位：秒）
      lastPlayTimeHideDelay: 0, // 提示文字展示时长（单位：秒）
      disableProgress: that.config.data.progressDragStatus == 0, // 禁止拖拽进度条
      download: that.config.data.downloadStatus == 1, // 视频下载
      hlsOpts: {
        xhrSetup: function (xhr, url) {
          console.log(that.options, "xhrSetup");
          xhr.setRequestHeader("accessKey", that.options.accessKeyId);
          xhr.setRequestHeader("accessSecret", that.options.accessKeySecret);
        },
      }, // hls.js可选配置项
    });
    console.log("this.player", this.player);

    this.$nextTick(() => {
      // logo
      if (that.logoUrl) {
      } else {
        that.setLogo();
      }

      // 水印
      that.setWatermark();
    });
    // 播放开始
    this.player.on("play", () => {
      this.$emit("play");
      this.isVideoEnd = false;
    });
    // 播放暂停
    this.player.on("pause", () => {
      this.$emit("pause");
      // 加载广告
      setTimeout(() => {
        !this.isVideoEnd && this.initPauseAD();
      }, 100);
    });
    // 播放结束
    this.player.on("ended", () => {
    console.log('---------------------------------999----------------------------------');
    
      this.$emit("end");
      // 加载片尾
      this.initED();
      this.isVideoEnd = true;
    });
    // 正在播放
    let currentTime = 0;
    this.player.on("timeupdate", (e) => {
      if (currentTime != parseInt(e.currentTime)) {
        currentTime = parseInt(e.currentTime);
        this.$emit("timeupdate", {
          currentTime,
          duration: e.duration,
          playbackRate: e.playbackRate,
        });
      }
    });

    // 进入全屏
    this.player.on("requestFullscreen", () => {
      this.setWatermark(this.options);
      this.$emit("fullScreen", true);
    });
    // 退出全屏
    this.player.on("exitFullscreen", () => {
      this.watermark.top = "0";
      this.watermark.left = "0";
      this.setWatermark(this.options);
      this.$emit("fullScreen", false);
    });

    this.$emit("player", this.player);
    return this.player;
  },
};
</script>

<style>
.playerBox {
  position: relative;
  background-color: #000;
  overflow: hidden;
}
.playerBox .errorTips {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 15;
}
.playerBox #OP,
.playerBox .OP,
.playerBox #ED,
.playerBox .ED {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 499;
}
.playerBox .OP,
.playerBox .ED {
  width: 100%;
  height: 100%;
}
.playerBox .player-container {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
}
.playerBox .logo {
  position: absolute;
  z-index: 4;
}
.playerBox .watermark {
  position: absolute;
  z-index: 5;
  transition: all 100s linear;
  left: 0;
  top: 0;
  transform: translate(0, 0);
}
.playerBox .volume {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(205, 205, 205, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 21;
  cursor: pointer;
}
.playerBox .volume-icon {
  width: 15px;
  height: 15px;
}
.playerBox .advertising-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playerBox .advertising-container {
  width: 500px;
  height: 300px;
  position: relative;
}
.playerBox .advertising {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.playerBox .advertising-close {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 7px;
}
.playerBox .advertising-close-img {
  width: 10px;
  height: 10px;
  display: block;
  transform: translateY(1px);
  margin-left: 5px;
}
</style>
